<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <div class="w-100 d-flex align-items-center justify-content-between">
        <h1>Homepage Videos</h1>
        <b-button
          size="sm"
          type="button"
          variant="primary"
          v-b-modal.modal-create
        >
          Add New Video
        </b-button>
      </div>
      <b-row align-content="center" v-if="faSetting && enSetting">
        <b-col cols="12">
          <div>
            <b-row>
              <b-col cols="12">
                <h4 class="mt-2">Persian Videos</h4>
                <hr />
              </b-col>
              <div
                class="w-100 d-flex align-items-center justify-content-center"
                v-if="
                  !faSetting.sliderVideos || faSetting.sliderVideos.length == 0
                "
              >
                <p class="text-center">No Videos Available</p>
              </div>
              <template v-else>
                <b-col
                  cols="12"
                  md="3"
                  class="my-1"
                  v-for="(video, index) in faSetting.sliderVideos"
                  :key="index"
                >
                  <b-card no-body :title="`Title: ${video.imageTitle}`">
                    <video
                      class="video-thumbnail"
                      style="cursor: pointer"
                      @click="renderImageModal(video)"
                      height="auto"
                      width="200"
                      :src="handleImageUrl(video.base64)"
                    ></video>
                    <small class="mt-1 text-black"
                      >Title: {{ video.imageTitle }}</small
                    >
                  </b-card>
                </b-col>
              </template>
            </b-row>
            <b-row>
              <b-col cols="12">
                <h4 class="mt-2">English Videos</h4>
                <hr />
              </b-col>
              <div
                class="w-100 d-flex align-items-center justify-content-center"
                v-if="
                  !enSetting.sliderVideos || enSetting.sliderVideos.length == 0
                "
              >
                <p class="text-center">No Videos Available</p>
              </div>
              <template v-else>
                <b-col
                  cols="12"
                  md="3"
                  v-for="(video, index) in enSetting.sliderVideos"
                  :key="index"
                >
                  <b-card no-body>
                    <video
                      class="video-thumbnail"
                      style="cursor: pointer"
                      @click="renderImageModal(video)"
                      height="auto"
                      width="200"
                      :src="handleImageUrl(video.base64)"
                    ></video>
                    <small class="mt-1 text-black"
                      >Title: {{ video.imageTitle }}</small
                    >
                  </b-card>
                </b-col>
              </template>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button variant="success" v-b-modal.modal-create>
          Add New Video
        </b-button>
      </div>
    </b-card>
    <b-modal
      id="modal-create"
      centered
      size="lg"
      ok-variant="success"
      title="Add New Video To Homepage"
      ok-title="Add"
      cancelTitle="Dismiss"
      @ok="pushNewAward"
    >
      <b-form @submit.prevent>
        <b-form-group label="Video Title" label-for="title">
          <b-form-input
            id="title"
            v-model="newVideoDetails.imageTitle"
            trim
            placeholder="Enter Video Title"
          />
        </b-form-group>
      </b-form>
      <b-form @submit.prevent>
        <b-form-group label="Link" label-for="link">
          <b-form-input
            id="link"
            v-model="newVideoDetails.link"
            trim
            placeholder="Enter Link"
          />
        </b-form-group>
      </b-form>
      <b-form @submit.prevent>
        <b-form-group label="Language" label-for="lang">
          <b-form-select
            id="lang"
            v-model="newVideoDetails.preRoute"
            :options="langsEnum"
            placeholder="Choose Your Desired Language"
          ></b-form-select>
        </b-form-group>
      </b-form>
      <b-form-group label="Video File" label-for="video">
        <b-form-file
          id="video"
          ref="refInputEl"
          accept="video/*"
          placeholder="Choose Video"
          @input="makeBase64Video"
        />
      </b-form-group>
      <div class="w-100 d-flex align-items-center justify-content-center my-2">
        <video
          controls
          v-if="videoPreview"
          :src="videoPreview"
          height="auto"
          width="400"
          class="rounded"
        />
      </div>
    </b-modal>
    <library-image-modal
      :tempMedia="tempVideo"
      :mediaType="'video'"
      @refetch="refetch"
    ></library-image-modal>
  </b-overlay>
</template>
<script>
export default {
  title: "Homepage Video Setting",
  mounted() {
    this.getFaSetting();
    this.getEnSetting();
  },
  data() {
    return {
      isLoading: false,
      faSetting: null,
      enSetting: null,
      newVideoDetails: {
        base64: null,
        objectId: 1,
        type: 16, // 16 is for VideoLibrary
        priority: 1,
        preRoute: "fa",
        imageTitle: null,
        link: null,
      },
      videoPreview: null,
      tempVideo: null,
      langsEnum: [
        {
          text: "Persian",
          value: "fa",
        },
        {
          text: "English",
          value: "en",
        },
      ],
    };
  },
  methods: {
    refetch() {
      this.getFaSetting();
      this.getEnSetting();
    },
    async updateSetting() {
      try {
        this.isLoading = true;
        let _this = this;
        let updateASetting = new UpdateASetting(_this);
        updateASetting.setRequestParamDataObj(_this.faSetting);
        await updateASetting.fetch((response) => {
          if (response.isSuccess) {
            _this.getFaSetting();
            _this.getEnSetting();
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getEnSetting() {
      try {
        this.isLoading = true;
        let _this = this;
        let getASingleSetting = new GetASingleSetting(_this);
        getASingleSetting.setRequestParam({ id: 1, preRoute: "en" });
        await getASingleSetting.fetch((response) => {
          if (response.isSuccess) {
            _this.enSetting = response.data;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getFaSetting() {
      try {
        this.isLoading = true;
        let _this = this;
        let getASingleSetting = new GetASingleSetting(_this);
        getASingleSetting.setRequestParam({ id: 1, preRoute: "fa" });
        await getASingleSetting.fetch((response) => {
          if (response.isSuccess) {
            _this.faSetting = response.data;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    pushNewAward() {
      this.newVideoDetails.priority =
        this.faSetting.sliderVideos && this.faSetting.sliderVideos.length > 0
          ? this.faSetting.sliderVideos.length + 1
          : 1;
      this.sendCreateFileRequest();
    },
    renderImageModal(video) {
      this.tempVideo = {
        pictureUrl: video.base64,
      };
      this.$bvModal.show("details-modal");
    },
    handleImageUrl(video) {
      return `${Helper.baseUrl}/${video}`;
    },
    async makeBase64Video() {
      const _this = this;
      let file = _this.$refs.refInputEl.files[0];
      _this.videoPreview = URL.createObjectURL(file);
      const result = await _this.readAsDataURL(file);
      _this.newVideoDetails.base64 = result;
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result);
        };
        fr.readAsDataURL(file);
      });
    },
    async sendCreateFileRequest() {
      try {
        this.isLoading = true;
        if (!this.newVideoDetails.link) {
          this.newVideoDetails.link = "#";
        }
        let _this = this;
        let createFile = new CreateFile(_this);
        createFile.setRequestParamDataObj(_this.newVideoDetails);
        await createFile.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Operation Was Succesful",
                icon: "CheckIcon",
                variant: "success",
                text: "File Uploaded Succesfully",
              },
            });
            _this.faSetting.videoIndex = _this.faSetting.videoIndex
              ? `${_this.faSetting.videoIndex},${response.data.imageName}`
              : `${response.data.imageName}`;
            _this.updateSetting();
            // _this.getFaSetting();
            _this.videoPreview = null;
            _this.newVideoDetails = {
              base64: null,
              objectId: 1,
              type: 16, // 16 is for VideoLibrary
              priority: null,
              preRoute: "fa",
            };
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },

  components: {
    BContainer,
    BPagination,
    BFormRow,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BBadge,
    BFormTextarea,
    BOverlay,
    BFormSelect,
    BModal,
    LibraryImageModal,
  },
};
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BBadge,
  BFormTextarea,
  BOverlay,
  BFormSelect,
  BModal,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { GetASingleSetting, UpdateASetting } from "@/libs/Api/Setting";
import { CreateFile } from "@/libs/Api/Media";
import Helper from "@/libs/Helper";
import LibraryImageModal from "@/views/components/utils/LibraryImageModal.vue";
</script>
<style scoped>
.video-thumbnail {
  max-height: 150px;
  height: 150px;
  object-fit: contain;
}
</style>
